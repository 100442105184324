import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { IBackendWorkExperience } from '../../../../../queries/directions/backend/types';

interface IProps {
  data: IBackendWorkExperience;
}

const BackendWorkExperience: FC<IProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabPrefix = data.cases[activeTab];

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>
        <h2>{data?.title}</h2>
        <p>{data?.desc}</p>
        <div className={styles.cardsWrap}>
          {data.cards.map((card) => (
            <div key={card.alt} className={styles.card}>
              <img src={card.url} alt={card.alt} />
            </div>
          ))}
        </div>
        <div className={styles.buttonsWrap}>
          {data.cases.map((item, index) => (
            <button
              key={item.title}
              type="button"
              className={`${styles.button} ${
                activeTab === index ? styles.button__active : ''
              }`}
              onClick={() => setActiveTab(index)}
            >
              {item.title}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.tabWrap}>
        <div className={styles.leftWrap}>
          <div className={styles.content}>
            <h3>{tabPrefix.title}</h3>
            <p>{tabPrefix.desc}</p>
          </div>

          <div className={styles.workersWrap}>
            {tabPrefix.workers.map((item) => (
              <div key={item.title} className={styles.workers}>
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>

          <div className={styles.stackWrap}>
            {tabPrefix.stack.map((item) => (
              <div key={item} className={styles.stack}>
                <div className={styles.stack__text}>{item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightWrap}>
          <img src={tabPrefix.image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default BackendWorkExperience;
