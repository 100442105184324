import React, { FC } from 'react';
import styles from './styles.module.scss';

export type IServices = {
  title: string;
  icon: string;
  list: string[];
};

const Services: FC<{ data: IServices }> = ({ data }) => (
  <section className={styles.wrap}>
    <div className={styles.titleWrap}>
      <div className={styles.iconWrap}>
        <img src={data.icon} alt="stack" width="100%" height="100%" />
      </div>
      <h2>{data.title}</h2>
    </div>
    <div className={styles.container}>
      <ul className={styles.list}>
        {data.list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Services;
