import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <path
      d="M29.485 3.562h-3.543a.521.521 0 00-.515.527v1.52H30v-1.52a.521.521 0 00-.515-.527z"
      fill="#4E3EB8"
    />
    <path
      d="M23.055 6.08h-3.542a.521.521 0 00-.515.528v2.645h4.572V6.608a.521.521 0 00-.515-.527z"
      fill="#272044"
    />
    <path d="M25.427 7.509H30v18.49h-4.573V7.508z" fill="#4E3EB8" />
    <path d="M18.998 11.155h4.572v18.751h-4.572v-18.75z" fill="#272044" />
    <path
      d="M25.427 27.9H30v2.005h-4.573V27.9zM7.328 7.25H2.515A.521.521 0 002 7.775v1.99h5.843v-1.99a.521.521 0 00-.515-.527zM2 11.666h5.843v18.24H2v-18.24z"
      fill="#4E3EB8"
    />
    <path
      d="M9.7 25.505h7.44v4.4H9.7v-4.4zM9.7 8.323h7.44v15.281H9.7V8.323zM16.625 2h-6.41a.521.521 0 00-.514.527v3.895h7.44V2.527A.521.521 0 0016.624 2z"
      fill="#684CDC"
    />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
