import React from 'react';
import {
  ExposedIcon,
  KtorIcon,
  MokoIcon,
  MongoDbIcon,
  PostgreSqlIcon,
  RedisIcon,
  SpringBootIcon,
  Yii2Icon,
} from './stack';

export const STACK_TEHCNOLOGIES_ICONS: {
  [key: string]: JSX.Element | undefined;
} = {
  'Spring Boot': <SpringBootIcon />,
  Ktor: <KtorIcon />,
  Exposed: <ExposedIcon />,
  'moko-libraries': <MokoIcon />,
  PostgreSQL: <PostgreSqlIcon />,
  MongoDB: <MongoDbIcon />,
  Redis: <RedisIcon />,
  Yii2: <Yii2Icon />,
};
