import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { BackendHeader } from '../../../../components/directions/backend/screens/BackendHeader';
import BackendWorkExperience from '../../../../components/directions/backend/screens/BackendWorkExperience';
import { IBackendQuery } from '../../../../queries/directions/backend/types';
import FullDevelopmentCycle from '../../../../components/directions/common/FullDevelopmentCycle';
import BackendDevelopers from '../../../../components/directions/backend/screens/BackendDevelopers';
import StackTechnology from '../../../../components/directions/common/StackTechnology';
import { IReviewsQuery } from '../../../../queries/reviews/types';
import Services from '../../../../components/directions/common/Services';
import HowWeWork from '../../../../components/directions/common/HowWeWork';
import HowCost from '../../../../components/directions/common/HowCost';
import { BackendReviews } from '../../../../components/directions/backend/screens/BackendReviews';
import Faq from '../../../../components/directions/common/Faq';
import { BackendForm } from '../../../../components/directions/backend/screens/BackendForm';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<IBackendQuery & IReviewsQuery, { locale: string }>;

const BackendPage: FC<IProps> = ({ data, pageContext: { locale = 'en' } }) => {
  const backend = data.backend.frontmatter;
  const reviews = data.reviews.edges;

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={backend.seo.title}
        description={backend.seo.description}
        keywords={backend.seo.keywords}
      >
        <BackendHeader title={backend.name} data={backend.header} />
        <BackendWorkExperience data={backend.workExperience} />
        <BackendDevelopers data={backend.backendDevelopers} />
        <StackTechnology data={backend.stackTechnology} />
        <Services data={backend.services} />
        <HowWeWork data={backend.howWeWork} />
        <FullDevelopmentCycle data={backend.fullDevelopmentCycle} />
        <HowCost data={backend.howCost} />
        <BackendReviews locale={locale} reviews={reviews} />
        <Faq data={backend.faq} />
        <BackendForm />
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    backend: markdownRemark(
      fileAbsolutePath: { regex: "/directions/tags/backend/" }
    ) {
      ...BackendFields
    }

    reviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/reviews/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...ReviewFields
    }
  }
`;

export default BackendPage;
