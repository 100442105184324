import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <path
      d="M23.608 12.735c-1.686-7.437-5.2-9.417-6.096-10.817A15.921 15.921 0 0116.532 0c-.048.66-.135 1.077-.696 1.578-1.128 1.005-5.917 4.908-6.32 13.358-.375 7.878 5.792 12.737 6.607 13.239.627.308 1.39.006 1.762-.277 2.973-2.04 7.035-7.48 5.726-15.163"
      fill="#684CDC"
    />
    <path
      d="M16.787 23.98c-.155 1.95-.266 3.082-.66 4.197 0 0 .258 1.856.44 3.823h.643a35.76 35.76 0 01.701-4.117c-.832-.41-1.092-2.192-1.124-3.904z"
      fill="#4E3EB8"
    />
    <path
      d="M17.91 27.884c-.84-.389-1.084-2.209-1.122-3.905.208-2.797.27-5.603.182-8.407-.044-1.47.021-13.625-.362-15.405.262.603.564 1.188.904 1.75.896 1.4 4.411 3.38 6.096 10.818 1.312 7.67-2.728 13.095-5.698 15.149z"
      fill="#272044"
    />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
