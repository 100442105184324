import React, { FC } from 'react';
import styles from './styles.module.scss';

export type IHowWeWork = {
  title: string;
  list: string[];
};

const HowWeWork: FC<{ data: IHowWeWork }> = ({ data }) => (
  <section className={styles.wrap}>
    <h2>{data.title}</h2>
    <div className={styles.container}>
      {data.list.map((card, index) => (
        <div key={card} className={styles.card}>
          <div className={styles.number}>{index + 1}</div>
          <div>{card}</div>
        </div>
      ))}
    </div>
  </section>
);

export default HowWeWork;
