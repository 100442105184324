import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_9759_4842)">
      <path
        d="M21.971 20.341c-.224-2.85-1.177-4.698-1.642-5.745-.464-1.047-1.177-2.027-1.178-2.025-.001.004-.642 3.335-1.795 5.745-.192.403-.448.905-.706 1.31-.795 1.355-1.95 2.65-2.638 3.964-.683 1.302-.81 2.592-.746 4.057.064 1.474.417 2.92.757 4.353 1.278-.266 2.39-.72 3.353-1.302a9.49 9.49 0 004.497-6.618s.022-.107.031-.238c.196-2.039.131-2.682.067-3.5z"
        fill="#272044"
      />
      <path
        d="M21.971 20.341c-.224-2.85-1.177-4.698-1.642-5.745-.464-1.047-1.177-2.027-1.178-2.025l-.17-.245C15.246 7.271 8.083 4.747 2.03 7.086c-.291 3.544 1.413 9.64 7.628 11.341 2.513.746 4.525.552 6.992 1.199 0 0 2.506.842 3.964 2.106.656.568 1.313 1.315 1.28 2.204.208-2.116.141-2.763.076-3.595z"
        fill="#4E3EB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.257 9.604c-.883-2.491-.509-4.16 1.107-6.484.77-1.108 2.1-2.394 3.267-3.12 4.705 2.843 6.444 8.138 4.714 13.055-1.26 3.578-2.44 5.078-5.428 8.76.348-3.943-1.09-6.676-2.504-9.714-.36-.772-.857-1.652-1.156-2.497z"
        fill="#4E3EB8"
      />
      <path
        d="M21.895 23.936c.033-.889-.624-1.636-1.28-2.204-1.458-1.264-3.965-2.106-3.965-2.106.258-.405.514-.907.706-1.31 1.153-2.41 1.794-5.74 1.795-5.745 0-.002.714.978 1.178 2.025.465 1.047 1.418 2.895 1.642 5.745.065.832.132 1.48-.076 3.595z"
        fill="#684CDC"
      />
    </g>
    <defs>
      <clipPath id="clip0_9759_4842">
        <path fill="#fff" d="M0 0H32V32H0z" />
      </clipPath>
    </defs>
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
