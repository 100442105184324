import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_9759_5284)">
      <path
        d="M23.441 22.84c.197-1.59.138-1.824 1.358-1.566l.31.026c.939.042 2.167-.146 2.888-.471 1.552-.7 2.473-1.866.942-1.56-3.492.7-3.732-.448-3.732-.448 3.688-5.308 5.229-12.046 3.898-13.695C25.476.627 19.194 2.755 19.09 2.81l-.034.006a12.819 12.819 0 00-2.33-.236c-1.58-.025-2.779.402-3.689 1.072 0 0-11.207-4.48-10.685 5.633.11 2.15 3.178 16.277 6.837 12.01a66.21 66.21 0 012.63-2.88c.641.414 1.41.625 2.215.55l.062-.052c-.02.194-.01.383.025.607-.942 1.022-.665 1.201-2.55 1.578-1.906.38-.786 1.06-.055 1.237.887.215 2.938.52 4.324-1.362l-.056.214c.37.287.345 2.063.398 3.331.052 1.269.14 2.453.406 3.15.267.699.581 2.496 3.058 1.982 2.07-.43 3.652-1.05 3.796-6.81"
        fill="#272044"
      />
      <path
        d="M23.441 22.84c.197-1.59.138-1.824 1.358-1.566l.31.026c.939.042 2.167-.146 2.888-.471 1.552-.7 2.473-1.866.942-1.56-3.492.7-3.732-.448-3.732-.448 3.688-5.308 5.229-12.046 3.898-13.695C25.476.627 19.194 2.755 19.09 2.81l-.034.006a12.819 12.819 0 00-2.33-.236c-1.58-.025-2.779.402-3.689 1.072 0 0-11.207-4.48-10.685 5.633.11 2.15 3.178 16.277 6.837 12.01a66.21 66.21 0 012.63-2.88c.641.414 1.41.625 2.215.55l.062-.052c-.02.194-.01.383.025.607-.942 1.022-.665 1.201-2.55 1.578-1.906.38-.786 1.06-.055 1.237.887.215 2.938.52 4.324-1.362l-.056.214c.37.287.345 2.063.398 3.331.052 1.269.14 2.453.406 3.15.267.699.581 2.496 3.058 1.982 2.07-.43 3.652-1.05 3.796-6.81"
        stroke="#272044"
        strokeWidth={2.50334}
      />
      <path
        d="M28.94 19.27c-3.493.698-3.733-.449-3.733-.449 3.687-5.308 5.229-12.046 3.899-13.695-3.63-4.499-9.912-2.371-10.017-2.316l-.034.006a12.826 12.826 0 00-2.33-.236c-1.58-.025-2.779.402-3.689 1.071 0 0-11.207-4.479-10.686 5.633.111 2.152 3.179 16.278 6.838 12.011 1.337-1.56 2.629-2.88 2.629-2.88.642.414 1.41.625 2.215.55l.063-.052c-.02.194-.01.383.025.607-.943 1.022-.666 1.201-2.55 1.578-1.906.38-.786 1.06-.055 1.237.886.215 2.937.52 4.323-1.362l-.055.214c.37.287.629 1.867.585 3.299-.043 1.432-.072 2.415.219 3.183.29.768.58 2.495 3.058 1.98 2.07-.43 3.142-1.545 3.291-3.405.106-1.322.346-1.127.36-2.309l.193-.56c.222-1.792.035-2.37 1.31-2.101l.31.026c.939.041 2.167-.146 2.888-.471 1.552-.7 2.473-1.866.942-1.56z"
        fill="#684CDC"
      />
      <path
        d="M15.988 20.297c-.096 3.335.024 6.694.36 7.51.337.816 1.058 2.404 3.535 1.889 2.07-.43 2.822-1.263 3.15-3.102.24-1.353.704-5.11.763-5.879M13.02 3.577S1.803-.87 2.325 9.242c.11 2.151 3.178 16.278 6.837 12.011 1.337-1.56 2.546-2.784 2.546-2.784M19.077 2.765c-.388.118 6.238-2.35 10.004 2.319 1.33 1.649-.211 8.387-3.899 13.695"
        stroke="#fff"
        strokeWidth={0.834447}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.182 18.78s.24 1.147 3.733.447c1.53-.306.61.86-.942 1.56-1.274.573-4.13.72-4.176-.072-.12-2.045 1.503-1.424 1.386-1.936-.106-.461-.832-.914-1.312-2.043-.42-.986-5.75-8.545 1.478-7.422.264-.053-1.885-6.669-8.648-6.776-6.762-.108-6.54 8.067-6.54 8.067"
        stroke="#fff"
        strokeWidth={0.834447}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M14.095 19.479c-.942 1.021-.665 1.2-2.55 1.577-1.906.381-.786 1.06-.055 1.237.887.215 2.938.52 4.324-1.363.422-.573-.003-1.487-.583-1.72-.28-.113-.654-.254-1.136.268v0z"
        stroke="#fff"
        strokeWidth={0.834447}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.034 19.46c-.095-.6.203-1.315.523-2.15.48-1.255 1.59-2.51.702-6.49-.66-2.966-5.096-.617-5.099-.215-.002.402.201 2.038-.074 3.944-.358 2.486 1.631 4.589 3.922 4.374"
        stroke="#fff"
        strokeWidth={0.834447}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.978 10.544c-.02.138.26.504.624.553.363.05.674-.237.694-.374.02-.138-.26-.289-.623-.338-.364-.05-.675.023-.694.16h0z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.278147}
      />
      <path
        d="M24.048 10.265c.02.137-.26.503-.623.552-.364.05-.675-.237-.695-.374-.02-.137.26-.289.623-.338.364-.05.675.022.695.16v0z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.139073}
      />
      <path
        d="M25.349 9.314c.06 1.077-.24 1.811-.277 2.958-.056 1.667.819 3.575-.5 5.485"
        stroke="#fff"
        strokeWidth={0.834447}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9759_5284">
        <path fill="#fff" transform="translate(1 1)" d="M0 0H30V30H0z" />
      </clipPath>
    </defs>
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
