import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
    {...rest}
  >
    <g clipPath="url(#clip0_9759_4994)">
      <path
        d="M28.19 23.319c-3.822 5.091-11.984 3.373-17.218 3.62 0 0-.926.051-1.86.205 0 0 .354-.15.804-.306 3.675-1.27 5.412-1.526 7.646-2.674 4.196-2.15 8.372-6.831 9.222-11.695-1.597 4.678-6.455 8.704-10.878 10.339-3.028 1.117-8.503 2.204-8.504 2.205a7.136 7.136 0 01-.221-.117C3.457 23.084 3.35 15.02 10.113 12.42c2.965-1.14 5.8-.514 9.006-1.277 3.419-.813 7.378-3.375 8.987-6.725 1.804 5.35 3.972 13.723.084 18.9zm.066-20.56a14.278 14.278 0 01-1.65 2.926C23.789 2.794 19.856.993 15.51.993 6.96.993.007 7.948.007 16.495c0 4.481 1.913 8.523 4.962 11.355l.34.301a1.326 1.326 0 01-.195-1.862 1.325 1.325 0 11.196 1.864l.231.204A15.428 15.428 0 0015.511 32c8.171 0 14.881-6.36 15.456-14.386.425-3.93-.735-8.92-2.711-14.855z"
        fill="#684CDC"
      />
    </g>
    <defs>
      <clipPath id="clip0_9759_4994">
        <path fill="#fff" d="M0 0H32V32H0z" />
      </clipPath>
    </defs>
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
