import React, { FC } from 'react';
import { ArrowRightIcon, FIreIcon } from '../../../../common/icons/basic';
import { Button } from '../../../common/Button';
import styles from './styles.module.scss';

export type IHowCost = {
  title: string;
  desc: string;
  card: {
    title: string;
    desc: string;
  };
};

const HowCost: FC<{ data: IHowCost }> = ({ data }) => {
  const scrollToForm = (): void => {
    const formElement: HTMLElement | null = document.getElementById('form');
    // eslint-disable-next-line no-unused-expressions
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{data.title}</h2>
        <p className={styles.desc}>{data.desc}</p>
        <div className={styles.offer}>
          <div>
            <FIreIcon size={96} color="#FFDB73" />
          </div>
          <div className={styles.content}>
            <h3>{data.card.title}</h3>
            <p>{data.card.desc}</p>
          </div>
          <button type="button" onClick={scrollToForm} className={styles.link}>
            <ArrowRightIcon />
          </button>
          <div className={styles.btnMobileContainer}>
            <Button onClick={scrollToForm}>Оставить заявку</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowCost;
