import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <path
      d="M29.32 22.393c-1.516.793-9.385 4.03-11.08 4.919-1.696.888-2.604.863-3.93.23-1.325-.635-9.671-4.029-11.184-4.752-.762-.358-1.15-.663-1.15-.949v-2.879s10.902-2.366 12.661-3.004c1.76-.637 2.368-.653 3.863-.104 1.495.548 10.457 2.165 11.937 2.702v2.838c0 .296-.34.583-1.116.997v.002z"
      fill="#4E3EB8"
    />
    <path
      d="M29.32 19.528c-1.516.79-9.385 4.026-11.08 4.899-1.696.873-2.604.865-3.93.233-1.325-.632-9.671-4.026-11.184-4.743-1.512-.718-1.541-1.22-.058-1.799 1.483-.579 9.81-3.84 11.57-4.48 1.758-.642 2.367-.656 3.862-.106s9.332 3.663 10.803 4.205c1.472.543 1.54.988.018 1.775v.016z"
      fill="#684CDC"
    />
    <path
      d="M29.32 17.704c-1.516.791-9.385 4.026-11.08 4.9-1.696.872-2.596.864-3.922.229-1.326-.636-9.68-4.01-11.192-4.732-.762-.362-1.15-.665-1.15-.953v-2.87s10.902-2.373 12.661-3.01c1.76-.638 2.368-.656 3.863-.1s10.457 2.158 11.937 2.699v2.838c0 .296-.34.591-1.116 1z"
      fill="#4E3EB8"
    />
    <path
      d="M29.32 14.825c-1.516.793-9.385 4.028-11.08 4.901-1.696.873-2.604.867-3.93.233-1.325-.633-9.671-4.014-11.184-4.737-1.512-.723-1.54-1.215-.058-1.795 1.481-.579 9.81-3.847 11.57-4.471 1.758-.624 2.367-.663 3.862-.12 1.495.542 9.332 3.681 10.803 4.208 1.472.527 1.54.99.018 1.775v.006z"
      fill="#684CDC"
    />
    <path
      d="M29.32 12.838c-1.516.793-9.385 4.03-11.08 4.901-1.696.871-2.596.865-3.922.233-1.326-.631-9.68-4.016-11.192-4.737-.762-.358-1.15-.663-1.15-.95v-2.88s10.902-2.366 12.661-3.002c1.76-.635 2.368-.655 3.863-.107 1.495.549 10.453 2.166 11.933 2.706v2.839c.004.3-.337.59-1.112.997z"
      fill="#4E3EB8"
    />
    <path
      d="M29.32 9.963c-1.516.778-9.385 4.034-11.08 4.899-1.696.865-2.604.863-3.93.233-1.325-.63-9.671-4.018-11.184-4.738-1.512-.719-1.541-1.218-.058-1.798 1.483-.58 9.81-3.845 11.57-4.479 1.758-.634 2.367-.653 3.862-.105S27.832 7.64 29.303 8.18c1.472.54 1.54.99.018 1.777v.006z"
      fill="#684CDC"
    />
    <path
      d="M16.653 5.875l1.878-.617-.51 1.217 1.912.716-2.463.256-.554 1.33-.89-1.483-2.849-.257 2.127-.766-.64-1.178 1.99.782zM16.772 13.637l-4.604-1.91 6.594-1.014-1.99 2.924zM10.39 7.93c1.943 0 3.524.612 3.524 1.36 0 .749-1.585 1.361-3.525 1.361s-3.526-.612-3.526-1.36c0-.75 1.58-1.362 3.526-1.362z"
      fill="#fff"
    />
    <path d="M22.85 7.554l3.903 1.542-3.898 1.541-.004-3.083z" fill="#272044" />
    <path
      d="M22.85 7.554l.005 3.083-.424.166-3.9-1.542 4.32-1.707z"
      fill="#4E3EB8"
    />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
