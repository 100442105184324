import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 32,
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px` }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...rest}
  >
    <path
      transform="rotate(45 11.5 2)"
      fill="#4E3EB8"
      d="M11.5 2H37.4617V14.9808H11.5z"
    />
    <path
      transform="rotate(45 20.679 11.18)"
      fill="#684CDC"
      d="M20.6787 11.1801H33.6595V24.160899999999998H20.6787z"
    />
    <path d="M20.679 11.18v9.18H11.5v-9.18h9.179z" fill="#272044" />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
