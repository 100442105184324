import React, { FC } from 'react';
import styles from './styles.module.scss';

export type IFullDevelopmentCycle = {
  title: string;
  cards: {
    iconUrl: string[];
    title: string;
    specialists: string;
    body: string;
  }[];
};

const FullDevelopmentCycle: FC<{ data: IFullDevelopmentCycle }> = ({
  data,
}) => (
  <section className={styles.wrap}>
    <h2>{data.title}</h2>
    <div className={styles.container}>
      {data.cards.map((card, index) => (
        <div key={card.title} className={styles.card}>
          <div className={styles.cardHeader}>
            <div className={styles.cardHeader__iconsWrap}>
              {card.iconUrl.map((img) => (
                <img key={img} src={img} alt={card.title} draggable={false} />
              ))}
            </div>
            <span className={styles.cardHeader__number}>{index + 1}</span>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.cardBody__title}>
              <span>{card.title}</span>
              <span className={styles.dot} />
              <span className={styles.cardBody__subtitle}>
                {card.specialists}
              </span>
            </div>
            <div>{card.body}</div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default FullDevelopmentCycle;
