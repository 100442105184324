import React, { FC } from 'react';
import styles from './styles.module.scss';
import { IBackendDevelopers } from '../../../../../queries/directions/backend/types';

interface IProps {
  data: IBackendDevelopers;
}

const BackendDevelopers: FC<IProps> = ({ data }) => (
  <section className={styles.wrap}>
    <h2>{data.title}</h2>
    <p>{data.desc}</p>
    <div className={styles.cardsWrap}>
      {data.cards.map((item) => (
        <div key={item.title} className={styles.card}>
          <img src={item.icon} alt={item.title} />
          <span
            className={styles.title}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        </div>
      ))}
    </div>
  </section>
);

export default BackendDevelopers;
